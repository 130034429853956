<div ufmasterdetail class="container">
    <uf-panel [class.scroll-all]="table" [class.col]="table" [class.panelWidth]="showFilters" class="container bg-gray">
        <div class="wrapper">
            <div [class.disable-scroll]="showFilters" class="report-panel">
                <div class="header-pane uf-app-bar flat stretch">
                    <div *ngIf="dateFilterConfigs.intervals" class="col-lg-3">
                        <uf-radio [columns]="incrementOptions.length" [options]="incrementOptions" [columns]="3"
                            [value]="interval" (valueChange)="incrementChange($event)" valueProperty="identifier" />
                    </div>
                    <button (click)="print()" type="button" class="uf-action">
                        <uf-icon name="print" />
                    </button>
                </div>

                <div *ngIf="!error" class="uf-grid condensed column-gap-sm pad-sides gap-bottom report">
                    <div *ngIf="dateFilterConfigs.startDate && this.dateFilterConfigs.endDate && this.dateFilterConfigs.presetRanges"
                        class="row space-children wrap gap-md-top">
                        <button (click)="dateIntervalPresetSelected(i)" type="button"
                            *ngFor="let preset of dateIntervalPresets; let i = index"
                            class="uf-button tertiary x-small">{{preset.label}}
                        </button>
                    </div>

                    <div class="col-12 gap-md-top">
                        <uf-filter-display [manager]="filterManagerAll" [value]="filterValues"
                            (valueChange)="filtersChange($event)" />
                    </div>

                    <uf-message *ngFor="let reportNotFound of reportsNotFound" [content]="reportNotFound" icon="warning"
                        class="col-12 warning gap-bottom" />

                    <us-report *ngFor="let reportConfig of reportConfigs" class="col-lg-{{reportConfig.width}}"
                        [reportConfig]="reportConfig" [class.paginated]="table" [bottomThreshold]="50" />
                </div>

            </div>


        </div>
    </uf-panel>

    <uf-drawer [showHandle]="true" [(open)]="showFilters" handleIcon="filter" class="share-space">
        <uf-filter-inputs *ngIf="filterManagerAll.entries.length && filterValues" [manager]="filterManagerAll"
            [value]="filterValues" (valueChange)="filtersChange($event)" (onClose)="showFilters = false" />
    </uf-drawer>
</div>