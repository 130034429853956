import { InjectionToken } from '@angular/core';
import { FileUploader, RuntimeDefinition } from '@unifii/library/common';
import { ClientGetOptions, Definition, FormData, Query } from '@unifii/sdk';

export enum SaveResult {
    Conflict,
    Failed,
    Queued,
    Succeed
}

export interface SaveOutput {
    result: SaveResult;
    data?: FormData;
}

export interface UsFormService {
    readonly bucket: string;
    init(bucket: string): void;
    getFileUploader(dataId: string): FileUploader;
    getFormData(formDataId: string): Promise<FormData>;
    getFormDataRevision(formDataId: string): Promise<string | undefined>;
    getFormDefinition(identifier: string, version?: number): Promise<RuntimeDefinition>;
    query(query: Query, options?: ClientGetOptions): Promise<FormData[]>;
    count(query: Query, options?: ClientGetOptions): Promise<number | undefined>;
    getDownloadUrl(query: Query): string;
    save(data: FormData, definition: Definition | RuntimeDefinition): Promise<SaveOutput>;
}

export type UsFormServiceFactoryLogicInterface = () => UsFormService;

// factory for creating form service instance, depends on UsFormServiceFactoryLogic
export const formServiceFactory = (formServiceFactoryLogic: UsFormServiceFactoryLogicInterface) => formServiceFactoryLogic();

export const UsFormService = new InjectionToken<UsFormService>('FormService');
export const UsFormServiceFactoryLogic = new InjectionToken<UsFormServiceFactoryLogicInterface>('FormService');
