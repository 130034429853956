import { HttpClientModule } from '@angular/common/http';
import { NgModule, RendererFactory2 } from '@angular/core';
import { ContextProvider, FieldDescriptionServiceProvider, ThemeProvider, ThemeService, WindowWrapper } from '@unifii/library/common';
import { PageComponentRegistry, ViewComponentRegistry } from '@unifii/library/smart-forms/display';
import { FormComponentRegistry, FormDescriptionListRegistry } from '@unifii/library/smart-forms/input';
import { CompanyFormProvider, ProvisioningAsyncValidatorDebounce, UserFormProvider, UserProvisioningModule } from '@unifii/user-provisioning';

import { RequiredUpdateModalComponent } from 'discover/app-update/required-update-modal.component';
import { CompanyFormPermissionsProvider } from 'discover/company-management/company-form-permissions-provider';
import { CompanyFormComponent } from 'discover/company-management/company-form.component';
import { DirectoryTemplateComponent } from 'discover/components/directory-template/directory-template.component';
import { LoadingBarComponent } from 'discover/components/loading-bar.component';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';
import { SignInComponent } from 'discover/components/sign-in/sign-in.component';
import { FileListDisplayComponent } from 'discover/content/file-list.component';
import { UdPageComponentRegistry } from 'discover/content/page-component-registry';
import { UdViewComponentRegistry } from 'discover/content/view-component-registry';
import { DiscoverContentComponentFactory } from 'discover/discover-content-node-factory';
import { UdFormComponentRegistry } from 'discover/form-component-registry';
import { UdFormDescriptionListRegistry } from 'discover/form-description-list-registry';
import { DiscoverIconsComponent } from 'discover/icons/icons.component';
import { LogoutInitStep } from 'discover/logout-init-step';
import { MainComponent } from 'discover/main.component';
import { FakePostUpdateHook } from 'discover/offline/fake-post-update-hook';
import { UpdateProgressComponent } from 'discover/offline/update-progress.component';
import { OfflineFormComponent } from 'discover/offline-forms/offline-form.component';
import { OfflineFormsListComponent } from 'discover/offline-forms/offline-forms-list.component';
import { PushNotificationsInitStep } from 'discover/push-nofications-init-step';
import { DISCOVER_REPORT_COMPONENTS } from 'discover/reports';
import { DiscoverContextProvider } from 'discover/services/discover-context-provider';
import { DiscoverFieldDescriptionService } from 'discover/services/discover-field-description.service';
import { DiscoverSettingsComponent } from 'discover/settings/settings.component';
import { AuthorizeComponent } from 'discover/user-access/authorize.component';
import { CompleteRegistrationComponent } from 'discover/user-access/complete-registration.component';
import { ContactInfoModalComponent } from 'discover/user-access/contact-info-modal.component';
import { LoginFormComponent } from 'discover/user-access/login/login-form.component';
import { LoginIdentityHoldComponent } from 'discover/user-access/login/login-identity-hold.component';
import { LoginComponent } from 'discover/user-access/login/login.component';
import { SSOComponent } from 'discover/user-access/login/sso.component';
import { MFAComponent } from 'discover/user-access/mfa.component';
import { PasswordChangeComponent } from 'discover/user-access/password-change.component';
import { RequestPasswordChangeComponent } from 'discover/user-access/request-password-change.component';
import { SelectProjectComponent } from 'discover/user-access/select-project.component';
import { TenantSelectorComponent } from 'discover/user-access/tenant-selector.component';
import { UserAccessBoxComponent } from 'discover/user-access/user-access-box.component';
import { UserAccessComponent } from 'discover/user-access/user-access.component';
import { USER_MANAGEMENT_COMPONENTS, UserFormPermissionsProvider } from 'discover/user-management';
import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { InitStep } from 'shell/init-step';
import { PostUpdateHook } from 'shell/offline/post-update-hook';
import { ShellModule } from 'shell/shell.module';

const createThemeProvider = (window: Window, rendererFactory: RendererFactory2): ThemeService => {
    const element = window.document.documentElement;

    return new ThemeService(element, rendererFactory);
};

@NgModule({
    imports: [
        HttpClientModule, ShellModule, UserProvisioningModule,
    ],
    declarations: [
        MainComponent, DiscoverSettingsComponent, DiscoverIconsComponent, LoadingBarComponent,
        UserAccessBoxComponent, MFAComponent, ContactInfoModalComponent, LoginComponent, LoginFormComponent,
        SSOComponent, UserAccessComponent, LoginIdentityHoldComponent, TenantSelectorComponent, SelectProjectComponent,
        CompleteRegistrationComponent, AuthorizeComponent, RequestPasswordChangeComponent, PasswordChangeComponent,
        CompanyFormComponent, OfflineFormsListComponent, OfflineFormComponent, UpdateProgressComponent,
        DirectoryTemplateComponent, RequiredUpdateModalComponent, SignInHistoryComponent, SignInComponent,
        FileListDisplayComponent,
        USER_MANAGEMENT_COMPONENTS, DISCOVER_REPORT_COMPONENTS,
    ],
    exports: [
        ShellModule,
        MainComponent, DiscoverSettingsComponent, DiscoverIconsComponent, LoadingBarComponent,
        UserAccessBoxComponent, MFAComponent, ContactInfoModalComponent, LoginComponent, LoginFormComponent,
        SSOComponent, UserAccessComponent, LoginIdentityHoldComponent, TenantSelectorComponent, SelectProjectComponent,
        CompleteRegistrationComponent, AuthorizeComponent, RequestPasswordChangeComponent, PasswordChangeComponent,
        CompanyFormComponent, OfflineFormsListComponent, OfflineFormComponent, UpdateProgressComponent,
        DirectoryTemplateComponent, RequiredUpdateModalComponent, SignInHistoryComponent, SignInComponent,
        FileListDisplayComponent,
        USER_MANAGEMENT_COMPONENTS, DISCOVER_REPORT_COMPONENTS,
    ],
    providers: [
        { provide: ContextProvider, useClass: DiscoverContextProvider },
        { provide: InitStep, useClass: LogoutInitStep, multi: true },
        { provide: InitStep, useClass: PushNotificationsInitStep, multi: true },
        // Theme
        { provide: ThemeProvider, useFactory: createThemeProvider, deps: [WindowWrapper, RendererFactory2] },
        // Content
        { provide: PageComponentRegistry, useClass: UdPageComponentRegistry },
        { provide: ViewComponentRegistry, useClass: UdViewComponentRegistry },
        { provide: ContentComponentFactory, useClass: DiscoverContentComponentFactory },
        { provide: FieldDescriptionServiceProvider, useClass: DiscoverFieldDescriptionService },
        // Forms
        { provide: FormComponentRegistry, useClass: UdFormComponentRegistry },
        { provide: FormDescriptionListRegistry, useClass: UdFormDescriptionListRegistry },
        // Provisioning FormPermissionsProviders
        { provide: ProvisioningAsyncValidatorDebounce, useValue: 750 },
        { provide: UserFormProvider, useClass: UserFormPermissionsProvider },
        { provide: CompanyFormProvider, useClass: CompanyFormPermissionsProvider },
        { provide: PostUpdateHook, useClass: FakePostUpdateHook },
    ],
})
export class DiscoverModule { }
