import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey } from '@unifii/library/common';
import { MeClient, MfaChallengeType, MfaStatus, OAuthWithMfaDevice, OAuthWithMfaDeviceSetup, OAuthWithMfaRecoveryCode, OAuthWithMfaSms, OAuthWithVirtualMfa, SMSChallenge, UfRequestError, arrayBufferToBase64Url, ensureUfRequestError, isArrayOfType, isBoolean, isDictionary, isOptionalType, isPasswordChangeRequiredErrorData, isValueOfStringEnumType } from '@unifii/sdk';
import { DeviceMfaNameModalComponent, MFA_DEFAULT_ISSUER, isAuthenticatorAssertionResponse, isAuthenticatorAttestationResponse } from '@unifii/user-provisioning';
import { isString } from 'markdown-it/lib/common/utils';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { PasswordChangePath } from 'discover/discover-constants';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { PasswordChangeComponentNavigationState, isPasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
    mfaStatus: MfaStatus;
    challenge?: `${MfaChallengeType}`;
    acceptedChallenges?: string;
    rememberMe?: boolean;
    password?: string;
    params?: MfaPrams;
    nextState?: Record<string, unknown>;
    nextRoute: string[];
}

interface MfaPrams {
    projectId?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
    isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.nextState, isDictionary) &&
    isArrayOfType(data.nextRoute, isString) &&
    isOptionalType(data.rememberMe, isBoolean) &&
    isOptionalType(data.password, isString) &&
    isOptionalType(data.challenge, isValueOfStringEnumType(MfaChallengeType)) &&
    isOptionalType(data.acceptedChallenges, isString) &&
    isOptionalType(data.params, isMfaParams);

const isMfaParams = (data: unknown): data is MfaPrams =>
    isDictionary(data) &&
    isOptionalType(data.projectId, isString);

@Component({
    selector: 'ud-mfa',
    templateUrl: 'mfa.html',
})
export class MFAComponent implements OnInit, OnDestroy {

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly commonTK = CommonTranslationKey;
    protected readonly mfaChallengeType = MfaChallengeType;
    protected challenge?: `${MfaChallengeType}`;
    protected acceptedChallenges: `${MfaChallengeType}`[];
    protected issuer: string;
    protected label: string;
    protected mfaStatus: MfaStatus;
    protected inProgress = false;

    private router = inject(Router);
    private userAccessManager = inject(UserAccessManager);
    private meClient = inject(MeClient);
    private config = inject(Config);
    private auth = inject(Authentication);
    private errorService = inject(ErrorService);
    private deviceService = inject(DeviceService);
    private modalService = inject(ModalService);
    private cdr = inject(ChangeDetectorRef);
    private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard
    private deviceMfaChallengeKey: string | undefined;

    ngOnInit() {
       this.issuer = this.config.unifii.companyName ?? MFA_DEFAULT_ISSUER;
       this.label = `(${this.config.unifii.tenantSettings?.name}) ${this.auth.userInfo?.username ?? ''}`;
       this.mfaStatus = this.state.mfaStatus;
       this.challenge = this.state.challenge;
       this.acceptedChallenges = this.createAcceptedChallenges();
    }

    ngOnDestroy() {
        this.userAccessManager.showError(null);
    }

    protected async verifyCredential(credential: PublicKeyCredential) {

        if (this.inProgress || !isAuthenticatorAssertionResponse(credential.response)) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        const params: OAuthWithMfaDevice = {
            id: credential.id,
            raw_id: arrayBufferToBase64Url(credential.rawId),
            type: credential.type,
            client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
            authenticator_data: arrayBufferToBase64Url(credential.response.authenticatorData),
            signature: arrayBufferToBase64Url(credential.response.signature),
        };

        try {
            await this.auth.login( params, this.state.rememberMe);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected async setupCredential(credential: PublicKeyCredential) {

        if (this.inProgress || !this.deviceMfaChallengeKey || !isAuthenticatorAttestationResponse(credential.response)) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        const params: OAuthWithMfaDeviceSetup = {
            id: credential.id,
            raw_id: arrayBufferToBase64Url(credential.rawId),
            type: credential.type,
            challenge_key: this.deviceMfaChallengeKey,
            client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
            attestation_object: arrayBufferToBase64Url(credential.response.attestationObject),
        };

        try {
            await this.auth.login( params, this.state.rememberMe);
            const name = await this.modalService.openMedium(DeviceMfaNameModalComponent) ?? '';

            await this.meClient.completeDeviceMfaSetup(this.deviceMfaChallengeKey, name);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }

	}

    protected async getSetupChallenge(): Promise<CredentialCreationOptions> {
        const { publicKey, challengeKey } = await this.meClient.setupDeviceMfa(this.config.unifii.baseUrl);

        this.deviceMfaChallengeKey = challengeKey;

        return { publicKey };
    }

    protected getVerifyChallenge(): Promise<CredentialRequestOptions> {
        return this.meClient.getDeviceMfaChallenge(this.config.unifii.baseUrl);
    }

    protected selectProvider(provider: MfaChallengeType) {
		this.challenge = provider;
        this.userAccessManager.showError(null);
	}

    protected async setVirtualMfaCode(secret: string) {
		await this.meClient.setVirtualMfaCode(secret);
	}

    protected smsChallenges(): Promise<SMSChallenge> {
        return this.meClient.getSmsChallenges();
	}

    protected async setRecoveryCodes(recoveryCodes: string[]) {
		await this.meClient.setRecoveryCodes(recoveryCodes);
        void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });
	}

    protected async verifyRecoveryCode(recovery_code: string) {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ recovery_code } satisfies OAuthWithMfaRecoveryCode, this.state.rememberMe);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected async verifySmsCode(code: string, challenge: string) {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ code, challenge } satisfies OAuthWithMfaSms);

            if (this.mfaStatus === MfaStatus.MfaSetupRequired) {
                await this.meClient.setSmsMfaEnabled();
            }

            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

	protected async verifyVirtualMfaToken(mfa_token: string) {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ mfa_token } satisfies OAuthWithVirtualMfa, this.state.rememberMe);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected logout() {
        if (this.inProgress) {
            return;
        }

        void this.auth.logout();
    }

    private handleVerifyAccepted() {
        if (this.auth.userInfo?.mfa?.hasRecoveryCodes === false) {

            // reset recovery code component is already used
            if (this.challenge === MfaChallengeType.RecoveryCode) {
                this.challenge = undefined;
                this.cdr.detectChanges();
            }

            this.mfaStatus = MfaStatus.MfaSetupRequired;
            this.acceptedChallenges = [MfaChallengeType.RecoveryCode];
            this.challenge = MfaChallengeType.RecoveryCode;

            return;
        }

        void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });
    }

    private handleVerifyError(error: UfRequestError) {

        if (isPasswordChangeRequiredErrorData(error.data)) {

            if (isPasswordChangeComponentNavigationState(this.state.nextState)) {
                void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });

                return;
            }

            void this.router.navigate(['/', PasswordChangePath], { state: { oldPassword: this.state.password, params: this.state.params } satisfies PasswordChangeComponentNavigationState });

            return;
        }

        this.userAccessManager.showError(this.getAuthError(error));
    }

    private getAuthError(error: UfRequestError): AppError {

        if (isDictionary(error.data) && error.data.error === 'invalid_grant') {
            return this.errorService.createError(error.data.error_description, error);
        }

        if (error.message) {
            this.errorService.createError(error.message, error);
        }

        return this.errorService.createError(this.errorService.unhandledErrorMessage, error);
    }

    private createAcceptedChallenges(): `${MfaChallengeType}`[] {

        let acceptedChallenges: `${MfaChallengeType}`[] = [];

        // during verify, accepted challenges is provided by backend
        if (this.state.acceptedChallenges) {
            acceptedChallenges = this.state.acceptedChallenges.split(',').filter((challenge): challenge is `${MfaChallengeType}` => isValueOfStringEnumType(MfaChallengeType)(challenge));
        } else {
            // during setup, accepted challenges is created by frontend
            acceptedChallenges = Object.values(MfaChallengeType);

            // remove sms if tenant doesn't support sms
            if (!this.config.unifii.tenantSettings?.isSmsMfaEnabled) {
                acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Sms);
            }

            // remove device if tenant doesn't support device mfa or device is not supported on the platform
            if (!this.config.unifii.tenantSettings?.isDeviceMfaEnabled || this.deviceService.isCapacitorAndroid() || this.deviceService.isCapacitorIOS()) {
                acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Device);
            }
        }

        return acceptedChallenges;
    }

}
