import { InjectionToken } from '@angular/core';
import { AppContext } from '@unifii/library/common';
import { AstNode, OAuthWithCode, OAuthWithPassword, OAuthWithResetToken, OAuthWithVirtualMfa, Permission, PermissionAction, ProjectInfo, UserInfo } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { AccessDeniedInfo } from 'shell/services/user-access-manager';

export interface LogoutArgs extends AccessDeniedInfo {
    askConfirmation?: boolean;
    skipRedirect?: boolean;
}

export interface PermissionFields {
    /** Whitelist of editable fields of the resource */
    fields?: string[]; // TODO rename to editFields
    /** Blacklist of editable fields of the resource */
    lockedFields?: string[];
    /** Whitelist of readable fields of the resource */
    readFields?: string[];
    /** Blacklist of readable fields of the resource */
    deniedFields?: string[];
}

export interface PermissionGrantedResult {
    granted: boolean;
    fieldsPermissions: PermissionFields;
    condition: AstNode | undefined;
}

export interface UserPermissionInfo extends Omit<Permission, 'path'> {
    path: string;
    pathRegEx: RegExp;
}

export interface Authentication {

    isAuthenticated: boolean;
    userInfo: UserInfo | null;
    userPermissions: Permission[];
    allowedProjects: ProjectInfo[];
    canAccessPreview: boolean;
    logouts: Observable<AccessDeniedInfo | void>;

    login: (info: OAuthWithPassword | OAuthWithCode | OAuthWithVirtualMfa | OAuthWithResetToken, rememberMe?: boolean) => Promise<void>;
    logout: (args?: LogoutArgs) => Promise<boolean>;
    clear: () => void;
    isMfaSetupRequired: () => boolean;
    getClaimValues: (type: string) => string[];
    getGrantedInfo: (path: string[], action: PermissionAction, target: any, context: AppContext, field?: string) => PermissionGrantedResult;
    getGrantedInfoWithoutCondition: (path: string[], action: PermissionAction, field?: string) => PermissionGrantedResult;
}

export const Authentication = new InjectionToken<Authentication>('Authentication');

