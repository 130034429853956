<ud-user-access-box>
    <ng-container content>

        <ng-template [ngIf]="linkError" [ngIfElse]="passwordReset">
            <uf-message [content]="linkError.message" class="error small gap-top" icon="errorSolid" />
            <div class="col-1of1 col center-all">
                <button [disabled]="busy" (click)="logout()" type="button" class="uf-button tertiary">
                    {{sharedTermsTK.ActionBack| translate}}
                </button>
            </div>
        </ng-template>

        <ng-template #passwordReset>

            <p>
                {{discoverTK.PasswordChangeDirectMessage | translate}}
            </p>

            <div class="grid">
                <form (ngSubmit)="submit()" class="col-1of1 grid">

                    <div class="col-1of1">
                        <uf-create-password [control]="control" [config]="passwordConfig"
                            [submitted]="control.submitted" />
                    </div>

                    <div *ngIf="submitError" class="col-1of1">
                        <uf-message [content]="submitError.message" class="error" icon="errorSolid" />
                    </div>

                    <div class="grid col-1of1">
                        <div class="col-1of1 col center-all">
                            <button [disabled]="busy" class="uf-button primary" type="submit">
                                {{discoverTK.PasswordChangeActionChangePassword | translate}}
                            </button>
                        </div>

                        <div class="col-1of1 col center-all">
                            <button [disabled]="busy" (click)="logout()" type="button" class="uf-button tertiary">
                                {{sharedTermsTK.ActionLogout | translate}}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </ng-template>
    </ng-container>
</ud-user-access-box>