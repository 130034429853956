import { Component, ComponentRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, ContextProvider, DataDisplayService, DataPropertyDescriptor, FileUploader, TemplateStringParser, UfExpressionFunctionsSet } from '@unifii/library/common';
import { DetailModule, TableDetailTemplate, TableFieldDescriptor, TableSourceType, isValueOfStringEnumType } from '@unifii/sdk';

import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { TableDetailData, TableItemLink } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { TableData } from 'shell/table/models';
import { TablePageConfig } from 'shell/table/table-page-config';

import { TableDetailContextProvider } from './table-detail-context-provider';
import { TableDetailComponent, TableModule } from './table-detail.component';

@Component({
    selector: 'us-table-detail-page',
    templateUrl: './table-detail-page.html',
    providers: [
        BreadcrumbsService,
        { provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] },
        {
            provide: FileUploader, useFactory: (route: ActivatedRoute, formService: UsFormService) =>
                formService.getFileUploader(route.snapshot.params.id as string),
                deps: [ActivatedRoute, UsFormService],
        },
    ],
})
export class TableDetailPageComponent implements TableDetailData, OnInit {

    // Set by content data resolver - START
    sourceType: TableSourceType; // TODO: convert to template once in console
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    item: TableData;
    title: string;
    modules: DetailModule[];
    fields: TableFieldDescriptor[];
    itemLink?: TableItemLink | undefined;
    // Set by content data resolver - END
    // Attributes accessed in TableDetailComponent
    pageMode: TableDetailTemplate;
    tableModules: TableModule[] = [];
    detailContextProvider: TableDetailContextProvider;
    tablePageConfig = inject(TablePageConfig);

    protected breadcrumbs: Breadcrumb[];
    protected ready: boolean;

    private tableDetailComponent: TableDetailComponent | undefined;
    private shell = inject(ShellService);
    private contextProvider = inject(ContextProvider);
    private dataResolver = inject(ContentDataResolver);
    private breadcrumbsService = inject(BreadcrumbsService);
    private templateStringParser = inject(TemplateStringParser);
    private dataDisplayService = inject(DataDisplayService);
    private readonly extendedFunctions = {
		...UfExpressionFunctionsSet,
		format: this.dataDisplayService.displayForTemplateExpression.bind(this.dataDisplayService),
	};

    async ngOnInit() {
        this.detailContextProvider = new TableDetailContextProvider(this.contextProvider, this.item);

        const context = Object.assign({ self: null, root: {} }, this.detailContextProvider.get());

        this.title = this.templateStringParser.parse(this.title, context, this.item, 'TableDetailPage.title', this.extendedFunctions);
        this.breadcrumbsService.title = this.title;
        this.shell.setTitle(this.title);

        this.tableModules = await this.buildTableModules(this.modules);
        const template = this.tablePageConfig.table.detail?.detailTemplate;

        this.pageMode = isValueOfStringEnumType(TableDetailTemplate)(template) ? template : TableDetailTemplate.PageView;

        this.ready = true;
    }

    updateItemLink() {
        if (!this.itemLink) {
            return;
        }

        if (this.tableDetailComponent) {
            this.tableDetailComponent.itemLink = this.itemLink;
        }
    }

    protected onActivate(componentRef: ComponentRef<TableDetailComponent>) {
        this.tableDetailComponent = componentRef.instance;
    }

    private async buildTableModules(modules: DetailModule[]): Promise<TableModule[]> {
        let index = 0;
        const tableModules: TableModule[] = [];

        for (const module of modules) {
            try {
                const tableData = await this.dataResolver.getTableData(module.identifier);
                const pageConfig = tableData.tablePageConfig;
                const moduleConfig = this.tablePageConfig.modules ? this.tablePageConfig.modules[index] : undefined;
                const title = (module.title ?? pageConfig.table.title).trim();

                tableModules.push({ detailModule: { ...module, title }, pageConfig, moduleConfig });
            } catch (e) {
                console.error('error', e);
            } finally {
                index++;
            }
        }

        return tableModules;
    }

}
