import { FileUploader } from '@unifii/library/common';
import { DOMExceptionAbortErrorName, FormDataClient, Progress, UfError, generateUUID } from '@unifii/sdk';

import { FileState } from 'shell/offline/forms/interfaces';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';

export class ShellFileUploader implements FileUploader {

    constructor(
        private formDataClient: FormDataClient,
        private offlineQ: OfflineQueue,
        private dataId: string,
    ) { }

    upload(file: File, progressCallback: (progress: Progress) => void = () => { }, signal?: AbortSignal): Promise<Progress> {

        if (!this.formDataClient) {
            throw new UfError('Set FormDataClient first');
        }

        if (signal && signal.aborted) {
            return Promise.reject(new DOMException('Upload aborted', DOMExceptionAbortErrorName));
        }

        const id = generateUUID();
        const size = file.size;

        return new Promise<Progress>(async(resolve, reject) => {

            if (signal) {
                signal.addEventListener('abort', () => { reject(new DOMException('Upload aborted', DOMExceptionAbortErrorName)); });
            }

            const start = performance.now();

            try {
                // Save to local cache first
                await this.offlineQ.saveAttachment(this.dataId, id, file);
                progressCallback({ done: Math.trunc(file.size * 0.2), total: size });
                console.log(`Attachment saved in ${performance.now() - start}ms`);

                // Check if online before proceed with upload
                if (!navigator.onLine) {
                    resolve({ total: size, done: size, id });

                    return;
                }

                // Save online
                const uploadProgress = await this.formDataClient.uploadAttachment(file, { id, signal, onProgress: (progress) => {
                    progressCallback({ total: file.size, done: Math.min(Math.trunc(size * 0.2 + progress.done * 0.8), size) });
                } });

                console.log(`Attachment uploaded in ${performance.now() - start}ms`);

                // update state to uploaded before reporting success
                const lockInfo = await this.offlineQ.getFileInfo(this.dataId, id);

                lockInfo.state = FileState.Uploaded;

                await this.offlineQ.updateFileInfo(this.dataId, lockInfo);

                resolve({
                    id: uploadProgress.id,
                    done: size,
                    total: size,
                });

            } catch (e) {
                console.warn('Error uploading attachment', e);
                reject(e);
            } finally {
                console.log(`Upload finished in ${performance.now() - start}ms`);
            }
        });
    }

    getUrl(id: string, width?: number): Promise<string> {
        if (!this.formDataClient) {
            throw new UfError('Set bucket first');
        }

		const params: Record<string, unknown> = { w: width };

		return this.formDataClient.getAttachmentUrl(id, { params });
    }

}
