import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, SharedTermsTranslationKey, SingleChoiceRadioModalComponent, ToastService } from '@unifii/library/common';
import { FormConfiguration, FormSettings, getPrintFormStyleChoiceModalData } from '@unifii/library/smart-forms';
import { PrintConfig, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { FormData, FormStyle, UfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { FormDataState } from 'shell/offline/forms/interfaces';
import { OfflineFileUploader } from 'shell/offline/forms/offline-file-uploader';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';

@Component({
    selector: 'ud-offline-form',
    templateUrl: './offline-form.html',
    styleUrls: ['./offline-form.less'],
    providers: [BreadcrumbsService],
})
export class OfflineFormComponent implements OnDestroy {

    @ViewChild(UfFormComponent, { static: true }) empForm: UfFormComponent;

    readonly discoverTK = DiscoverTranslationKey;

    error: Error | undefined;
    definition: RuntimeDefinition;
    formData: FormData;
    isDisabled: boolean;
    config: FormConfiguration;
    printConfig: PrintConfig | null;
    breadcrumbs: Breadcrumb[];

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private context: DiscoverContext,
        @Inject(FormSettings) private settings: FormSettings,
        private offlineQ: OfflineQueue,
        private toastService: ToastService,
        private errorService: ErrorService,
        private translate: TranslateService,
        private breadcrumbsService: BreadcrumbsService,
        private modalService: ModalService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
    ) {
        this.config = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit),
        };

        this.subscriptions.add(this.route.params.subscribe((p) => {
            void this.loadData(p.key);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    back() {
        void this.router.navigate(['..'], { relativeTo: this.route });
    }

    async submit(submitArgs: SubmitArgs) {
        try {
            this.error = undefined;
            const savedData = await this.offlineQ.save(submitArgs.data, this.definition);

            submitArgs.done(savedData);
            this.toastService.success(this.translate.instant(DiscoverTranslationKey.OfflineFormFeedbackStoreSuccess));
            this.back();
        } catch (error) {
            this.error = this.errorService.createError(this.errorService.offlineFormSaveErrorMessage, error);
        }
    }

    async print() {

        if (!this.settings.uploader) {
            return;
        }

        let logo: string | undefined;

        // Set project logo
        if (this.context.project?.logo?.url) {
            logo = this.context.project.logo.url;
        }

        const formStyle = (await this.modalService.openFit(SingleChoiceRadioModalComponent, getPrintFormStyleChoiceModalData(this.translate)))?.identifier as FormStyle | undefined;

        if (formStyle) {
            this.printConfig = {
                definition: JSON.parse(JSON.stringify(this.definition)),
                data: JSON.parse(JSON.stringify(this.formData)),
                logoUrl: logo,
                uploader: this.settings.uploader,
                summary: formStyle === FormStyle.Summary,
            };
        }
    }

    private async loadData(dataId: string) {

        this.error = undefined;

        try {
            const info = await this.offlineQ.getFormInfo(dataId);
            const data = await this.offlineQ.getData(dataId);

            if (info == null || data == null) {
                throw new UfError(this.errorService.offlineFormLoadErrorMessage);
            }

            if (info.status === FormDataState.Conflicted) {
                this.isDisabled = true;
            }

            this.settings.uploader = new OfflineFileUploader(this.offlineQ, dataId);
            this.definition = await this.runtimeDefinitionAdapter.transform(info.form);
            this.formData = data;

            this.breadcrumbsService.title = this.definition.label;
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();

        } catch (error) {
            this.error = this.errorService.createError(this.errorService.offlineFormLoadErrorMessage, error);
        }
    }

}
