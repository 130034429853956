import { SortStatus, TableConfigColumn, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalytics, RequestAnalyticsOrigin, isNotNull } from '@unifii/sdk';

import { RiskMatrixEntry, RiskMatrixService } from './risk-matrix-service';

export class RiskMatrixDatasource extends TableDataSource<RiskMatrixEntry> {

    readonly filtered: boolean;
    readonly sorted: boolean;

    private readonly analytics: RequestAnalytics = { origin: RequestAnalyticsOrigin.Report, id: 'risk-report' };
    private params: Record<string, unknown>;

    constructor(
        private riskMatrixService: RiskMatrixService,
        private filters: Record<string, unknown>,
        private sort: SortStatus | undefined,
    ) {
        super();

        this.filtered = !!Object.values(this.filters).filter(isNotNull).length;
        this.sorted = !!this.sort;
        this.params = Object.assign({}, this.filters);
        if (this.sort) {
            this.params.sort = this.sort.toString();
        }

    }

    load(options?: TableDataSourceQueryOptions) {

        const params = Object.assign({}, this.params, { limit: options?.limit, offset: options?.offset });

        this.riskMatrixService.getEntries(params, this.analytics).then((entries) => {
            this.stream.next({ data: entries });
        }).catch((error) => {
            this.stream.next({ error });
        });
    }

    downloadTableAsCsv(columns: TableConfigColumn<RiskMatrixEntry>[]) {
        const params = Object.assign({}, this.params);

        this.riskMatrixService.getEntries(params, this.analytics).then((entries) => {
            const rows: string[] = [];
            const cols: (keyof RiskMatrixEntry)[] = columns.map((c) => c.name as keyof RiskMatrixEntry);
            const colLabels: string = [columns.map((c) => c.label ?? '')].join(',');

            rows.push(colLabels);

            for (const entry of entries ?? []) {
                const temp: (string|number)[] = [];

                // push with respect to column order
                for (const col of cols) {
                    temp.push(entry[col]);
                }

                rows.push(temp.join(','));
            }

            const file = new Blob([rows.join('\n')], { type: 'text/csv' });
            const link = document.createElement('a');

            link.download = 'Risk Matrix.csv';

            link.href = window.URL.createObjectURL(file);

            link.click();
        }).catch((error) => {
            this.stream.next({ error });
        });
    }

}
