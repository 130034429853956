import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

export type ConflictModalAction = 'Close' | 'Discard' | 'Save';
export const ConflictModalAllActions: ConflictModalAction[] = ['Close', 'Discard', 'Save'];

@Component({
	selector: 'us-conflict-modal',
	templateUrl: './conflict-modal.html',
	standalone: false,
})
export class ConflictModalComponent implements Modal<ConflictModalAction[], ConflictModalAction>, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<ConflictModalAction[], ConflictModalAction>>(ModalRuntime);
	data = inject<ConflictModalAction[]>(ModalData);

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected showsClose: boolean;
	protected showsDiscard: boolean;
	protected showsSave: boolean;

	ngOnInit() {
		this.showsClose = this.data.includes('Close');
		this.showsDiscard = this.data.includes('Discard');
		this.showsSave = this.data.includes('Save');
	}

	protected apply(action: ConflictModalAction) {
		this.runtime.close(action);
	}

}
