import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { DiscoverContext } from 'discover/discover-context';
import { Authentication } from 'shell/services/authentication';

/** responsible for logging the user out if they are in the middle of password resetting close/refresh the app */
export const passwordResetGuard: CanActivateFn = () => {

    const context = inject(DiscoverContext);
    const authentication = inject(Authentication);

    // Guard access token
    if (context.isPasswordReset) {
        void authentication.logout();

        return false;
    }

    return true;
};
