import { TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { ClientGetOptions, FormData, Query, RequestAnalyticsOrigin, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { ShellFormService } from 'shell/form/shell-form.service';

export class BucketTableDataSource extends TableDataSource<FormData> {

    private _countPromise: Promise<number | undefined>;
    private shellFormService: ShellFormService;
    private tableIdentifier: string;
    private tableInputManager: TableInputManager<FilterValue, FilterEntry>;
    private tableInputs: TableInputs<FilterValue> | undefined;
    private showCount: boolean;
    private include: string[] | undefined;

    constructor(options: {
        shellFormService: ShellFormService;
        tableIdentifier: string;
        tableInputManager: TableInputManager<FilterValue, FilterEntry>;
        tableInputs?: TableInputs<FilterValue>;
        showCount?: boolean;
        include?: string[];
    }) {
        super();

        this.shellFormService = options.shellFormService;
        this.tableIdentifier = options.tableIdentifier;
        this.tableInputManager = options.tableInputManager;
        this.tableInputs = options.tableInputs;
        this.showCount = !!options.showCount;
        this.include = options.include;

        this.loadCount();
    }

    get filtered(): boolean {
        return isStringTrimmedNotEmpty(this.tableInputs?.q);
    }

    get sorted(): boolean {
        return this.tableInputs?.sort != null;
    }

    override get count(): Promise<number | undefined> {
        return this._countPromise;
    }

    private get clientGetOptions(): ClientGetOptions {
        return {
            signal: this.loadController?.signal,
            analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier },
        };
    }

    load(options?: TableDataSourceQueryOptions) {
        const query = this.buildQuery(options, this.include);

        return this.shellFormService.query(query, this.clientGetOptions).then(
            (data) => { this.stream.next({ data }); },
            (error) => { this.stream.next({ error }); },
        );
    }

    override getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
        const query = this.buildQuery({ ...options, limit: options?.limit }, this.include);

        return this.shellFormService.getDownloadUrl(query);
    }

    private buildQuery(options?: TableDataSourceQueryOptions, include?: string[]): Query {
        let query = this.tableInputManager
            .createQuery(this.tableInputs)
            .limit(options?.limit, options?.offset);

        if (include) {
            query = query.include(include);
        }

        return query;
    }

    private loadCount() {
        if (!this.showCount) {
            return;
        }

        this._countPromise = this.shellFormService.count(this.buildQuery(), this.clientGetOptions);
    }

}
