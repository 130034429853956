import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterEntry, FilterEntryAdapter, FilterManager, FilterSerializer, FilterType, FilterValue, HierarchyUnitProvider, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { DATE_DATA_FORMAT, DataSeed, Dictionary, Option, isArrayOfType, isDictionary, isNumber, isOptionalType, isString, isStringNotEmpty } from '@unifii/sdk';
import { format, sub, subDays } from 'date-fns';
import { Subscription } from 'rxjs';

import { CustomReport } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ReportFilterSerializer } from 'discover/reports/report-filter-serializer';
import { cleanObj, getEndOf, getStartOf } from 'discover/reports/report-functions';
import { ReportConfig, ReportCustomFilterConfig, ReportDateFilterConfig, ReportService } from 'discover/reports/report-service';
import { ReportComponent } from 'discover/reports/report.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { ShellTranslationKey } from 'shell/shell.tk';

enum IncrementOption {
	Daily = 'daily',
	Weekly = 'weekly',
	Monthly = 'monthly',
}

type CollectionDataSeedWithIdentifierProperty = DataSeed & { _identifierProperty: string };

@Component({
	selector: 'ud-report-page',
	templateUrl: './report-page.html',
	providers: [ReportService],
	styleUrls: ['./report-page.less'],
	standalone: false,
})
export class ReportPageComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChildren(ReportComponent) reportComponents: QueryList<ReportComponent>;

	protected readonly shellTK = ShellTranslationKey;
	protected readonly incrementOptions: Option[] = [
		{ identifier: IncrementOption.Daily, name: inject(TranslateService).instant(ShellTranslationKey.ReportIncrementDaysLabel) },
		{ identifier: IncrementOption.Weekly, name: inject(TranslateService).instant(ShellTranslationKey.ReportIncrementWeeksLabel) },
		{ identifier: IncrementOption.Monthly, name: inject(TranslateService).instant(ShellTranslationKey.ReportIncrementMonthsLabel) },
	];

	protected reportConfigs: ReportConfig[] = [];
	protected showFilters = false;
	protected table = false;
	protected filterManagerAll: FilterManager<FilterValue, FilterEntry>;
	protected filterManagerStandard: FilterManager<FilterValue, FilterEntry>;
	protected filterValues?: Dictionary<FilterValue>;
	protected dateFilterValues: Dictionary<FilterValue>;
	protected currentFilterValues: Dictionary<FilterValue>;
	protected currentDateFilterValues: Dictionary<FilterValue>;
	protected dateFilterConfigs: ReportDateFilterConfig = {};
	protected start: string;
	protected end: string;
	protected preset: string | undefined;
	protected interval: string = IncrementOption.Daily;
	protected error: any;
	protected reportsNotFound: string[] = [];
	protected dateIntervalPresets: { label: string; start: string; end: string }[] = [];

	private window = inject(WindowWrapper);
	private service = inject(ReportService);
	private translate = inject(TranslateService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private nav = inject(NavigationService);
	private filterEntryAdapter = inject(FilterEntryAdapter);
	private reportService = inject(ReportService);
	private hierarchyUnitProvider = inject<HierarchyUnitProvider>(HierarchyUnitProvider);
	private filterSerializer = inject<FilterSerializer<FilterValue, FilterEntry>>(FilterSerializer);
	private firstEntry = true;
	private defaultStart: string;
	private defaultEnd: string;
	private defaultInterval = IncrementOption.Daily;
	private reportIds: string[];
	private subscriptions = new Subscription();

	async ngOnInit() {
		const node = this.nav.current;

		if (node?.tags?.length) {
			this.reportIds = node.tags.filter((tag) => tag !== CustomReport);
		} else {
			this.error = { message: this.translate.instant(DiscoverTranslationKey.ReportErrorNotConfigured) };
		}

		this.defaultStart = format(subDays(new Date(), 27), DATE_DATA_FORMAT);
		this.defaultEnd = format(new Date(), DATE_DATA_FORMAT);
		this.start = this.defaultStart;
		this.end = this.defaultEnd;

		this.filterManagerAll = new FilterManager([], this.hierarchyUnitProvider, new ReportFilterSerializer(this.filterSerializer), null);
		this.filterManagerStandard = new FilterManager([], this.hierarchyUnitProvider, new ReportFilterSerializer(this.filterSerializer), null);

		for (const reportId of this.reportIds) {
			try {
				this.reportConfigs.push(await this.service.getConfig(reportId));
			} catch (e) {
				this.reportsNotFound.push(this.translate.instant(ShellTranslationKey.ReportNotFound, { name: reportId }));
				console.warn(`Failed to load report with id ${reportId}`, e);
			}
		}

		await this.initFilters();

		// enable table scroll & sticky header
		if (this.reportConfigs.find((r) => r.chartType === 'table')) {
			this.table = true;
		}

		this.subscriptions.add(this.route.params.subscribe(() => {
			void this.routeChanged();
		}));
	}

	ngAfterViewInit() {
		this.subscriptions.add(this.reportComponents.changes.subscribe(() => {
			if (this.filterValues) {
				this.loadReports();
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected filtersChange(event?: Record<string, unknown>) {
		if (!this.filterValues) {
			return;
		}

		if (Object.keys(this.filterValues).length === 0) {
			this.loadDefaultFilterValues();
		}

		if (event && isOptionalType(event.end, isString) && event.end) {
			this.end = event.end;
		} else if (event && isOptionalType(event.start, isString) && event.start) {
			this.start = event.start;
		} else if (event && isDictionary(event.date) && isOptionalType(event.date.from, isString) && isOptionalType(event.date.to, isString)) {
			const { from, to } = event.date;

			if (from && this.start !== from) {
				this.start = from;
				this.preset = undefined;
			}
			if (to && this.end !== to) {
				this.end = to;
				this.preset = undefined;
			}
		}

		const serialized = this.filterManagerStandard.serializeAll(this.filterValues);
		const dateFilters = cleanObj({ start: this.start, end: this.end, interval: this.interval, preset: this.preset });

		void this.router.navigate([{ ...serialized, ...dateFilters }], { relativeTo: this.route });
	}

	protected incrementChange(v: string) {
		this.interval = v;
		this.populateDateRangeOptions(false);
		this.filtersChange();
	}

	protected dateIntervalPresetSelected(i: number) {
		const interval = this.dateIntervalPresets[i];

		if (!interval) {
			return;
		}

		this.start = interval.start;
		this.end = interval.end;
		this.filtersChange();
	}

	protected print() {
		this.window.print();
	}

	private loadDefaultFilterValues() {
		this.start = this.defaultStart;
		this.end = this.defaultEnd;
		this.interval = this.defaultInterval;

		const dateFilters = { 'date': { 'from': this.start, 'to': this.end } };

		this.filterValues = Object.assign({}, dateFilters, { 'interval': this.interval });
	}

	private async routeChanged() {
		const params = Object.assign({}, this.route.snapshot.params);

		this.start = params.start ?? this.start;
		this.end = params.end ?? this.end;
		this.interval = params.interval ?? this.interval;
		this.preset = params.preset;

		const nextFilters = await this.filterManagerStandard.deserializeAll(params);
		const nextDateFilters = this.getConfiguredDateFilterValues({ start: this.start, end: this.end }, this.dateFilterConfigs);
		const singleDateFilters = this.dateFilterConfigs.startDate !== this.dateFilterConfigs.endDate ? { 'start': this.start, 'end': this.end } : {};

		this.filterValues = Object.assign({}, nextFilters, { 'date': nextDateFilters }, singleDateFilters, { 'preset': this.preset, 'interval': this.interval });
		this.dateFilterValues = Object.assign({}, nextDateFilters, { 'interval': this.interval });
		const changed =
            JSON.stringify(this.filterValues) !== JSON.stringify(this.currentFilterValues);

		this.currentFilterValues = JSON.parse(JSON.stringify(this.filterValues));
		this.currentDateFilterValues = JSON.parse(JSON.stringify(nextDateFilters));

		if (changed || this.firstEntry) {
			this.loadReports();
		}

		this.firstEntry = false;
	}

	private async initFilters() {
		try {
			this.createFilterEntries();
			await this.routeChanged();
		} catch (e) {
			console.error(e);
			this.error = (e as Error).message || this.translate.instant(SharedTermsTranslationKey.ErrorUnknown);
		}
	}

	private populateDateRangeOptions(initial: boolean) {

		if (!this.dateFilterConfigs.startDate || !this.dateFilterConfigs.endDate || !this.dateFilterConfigs.presetRanges) {
			return;
		}
		this.dateIntervalPresets = [];

		// On first call, check params as source of truth. Otherwise use current state
		const interval = initial ? this.route.snapshot.params.interval ?? this.interval : this.interval;

		if (interval === `${IncrementOption.Daily}`) {
			let date = new Date();
			let start = format(getStartOf(date, 'day'), DATE_DATA_FORMAT);
			let end = format(getEndOf(date, 'day'), DATE_DATA_FORMAT);

			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeToday), start, end });

			start = format(getStartOf(sub(date, { days: 6 }), 'day'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange7Days), start, end });

			start = format(getStartOf(sub(date, { days: 13 }), 'day'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange14Days), start, end });

			start = format(getStartOf(sub(date, { days: 20 }), 'day'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange21Days), start, end });

			start = format(getStartOf(sub(date, { days: 27 }), 'day'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange28Days), start, end });

			start = format(getStartOf(date, 'month'), DATE_DATA_FORMAT);
			end = format(getEndOf(date, 'month'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeThisMonth), start, end });

			date = sub(date, { months: 1 });
			start = format(getStartOf(date, 'month'), DATE_DATA_FORMAT);
			end = format(getEndOf(date, 'month'), DATE_DATA_FORMAT);
			this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeLastMonth), start, end });

			this.updatePresetFilterEntry();

			return;
		}

		let date = new Date();
		let start = format(getStartOf(date, 'month'), DATE_DATA_FORMAT);
		let end = format(getEndOf(date, 'month'), DATE_DATA_FORMAT);

		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeThisMonth), start, end });

		date = sub(date, { months: 1 });
		start = format(getStartOf(date, 'month'), DATE_DATA_FORMAT);
		end = format(getEndOf(date, 'month'), DATE_DATA_FORMAT);
		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeLastMonth), start, end });

		date = getEndOf(new Date(), 'month');
		end = format(date, DATE_DATA_FORMAT);
		start = format(getStartOf(sub(date, { months: 2 }), 'month'), DATE_DATA_FORMAT);
		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange3Months), start, end });

		date = getEndOf(new Date(), 'month');
		start = format(getStartOf(sub(date, { months: 5 }), 'month'), DATE_DATA_FORMAT);
		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange6Months), start, end });

		date = getEndOf(new Date(), 'month');
		start = format(getStartOf(sub(date, { months: 8 }), 'month'), DATE_DATA_FORMAT);
		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange9Months), start, end });

		date = getEndOf(new Date(), 'month');
		start = format(getStartOf(sub(date, { months: 11 }), 'month'), DATE_DATA_FORMAT);
		this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange12Months), start, end });

		this.updatePresetFilterEntry();
	}

	private loadReports() {
		for (const reportComponent of this.reportComponents) {
			try {
				if (!this.filterValues) {
					return;
				}
				const filters = this.getQueryFilters();

				void reportComponent.loadData(filters);
			} catch (e) {
				console.error(e);
			}
		}
	}

	private createFilterEntries() {
		let customFilters : ReportCustomFilterConfig[] = [];

		this.reportConfigs.forEach((config) => {
			if (config.customFilters) {
				// combine filters from all configs, exclude duplicate ids
				customFilters = [...customFilters, ...config.customFilters.filter((customFilter) => !customFilters.find((filter) => filter.identifier === customFilter.identifier))];
			}

			if (config.dateFilters) {
				this.dateFilterConfigs = {
					startDate: this.dateFilterConfigs.startDate ?? config.dateFilters.startDate,
					endDate: this.dateFilterConfigs.endDate ?? config.dateFilters.endDate,
					presetRanges: this.dateFilterConfigs.presetRanges ?? config.dateFilters.presetRanges,
					intervals: this.dateFilterConfigs.intervals ?? config.dateFilters.intervals,
				};
			}

			if (this.dateFilterConfigs.startDate && this.dateFilterConfigs.endDate) {
				const dateFilterEntry = this.filterEntryAdapter.transform({
					type: FilterType.DateRange,
					identifier: 'date',
					label: 'Date',
					translateService: this.translate,
				});

				this.filterManagerAll.add(dateFilterEntry);

			} else if (this.dateFilterConfigs.startDate || this.dateFilterConfigs.endDate) {
				const dateFilterEntry = this.filterEntryAdapter.transform({
					type: FilterType.Date,
					identifier: this.dateFilterConfigs.startDate ? 'start' : 'end',
					label: this.dateFilterConfigs.startDate ? 'Start' : 'End',
					translateService: this.translate,
				});

				this.filterManagerAll.add(dateFilterEntry);
			}

			this.populateDateRangeOptions(true);

		});

		for (const customFilter of customFilters) {
			const loader = this.reportService.createFilterLoader(customFilter.loader);

			// TODO Fix the create signature to accept directly a Loader
			const filterEntry = this.filterEntryAdapter.transform({
				type: this.reportService.getFilterType(customFilter.type, loader),
				identifier: customFilter.identifier,
				label: customFilter.label,
				options: customFilter.options,
				loader,
				translateService: this.translate,
			});

			if (filterEntry) {
				this.filterManagerStandard.add(filterEntry);
				this.filterManagerAll.add(filterEntry);
			}
		}

		this.filtersChange();
	}

	private getConfiguredDateFilterValues(filterValues: Dictionary<FilterValue>, filterConfig: ReportDateFilterConfig): Dictionary<FilterValue> {
		const dateFilterValues: Dictionary<FilterValue> = {};

		if (filterConfig.startDate) {
			dateFilterValues.from = filterValues.start ?? null;
		}
		if (filterConfig.endDate) {
			dateFilterValues.to = filterValues.end ?? null;
		}

		if (filterConfig.presetRanges) {
			dateFilterValues.preset = filterValues.preset ?? null;
		}

		return dateFilterValues;
	}

	private getQueryFilters(): Record<string, FilterValue> {
		if (!this.filterValues) {
			return {};
		}
		// using date range in filter has different keys than the backend expects
		const cleanedDateFiltersValues = Object.entries(this.dateFilterValues).reduce((acc:Dictionary<FilterValue>, [key, value]) => {
			if (key === 'from') {
				acc.start = value;
			} else if (key === 'to') {
				acc.end = value;
			} else {
				acc[key] = value; // Keep any other keys unchanged
			}

			return acc;
		}, {});

		const filters = { ...this.filterManagerStandard.serializeAll(this.filterValues), ...cleanedDateFiltersValues };

		const filterValuesToSwap = Object
			.entries(this.filterValues)
			.filter((entry): entry is [string, CollectionDataSeedWithIdentifierProperty[]] => {
				const filterValue = entry[1];

				return Array.isArray(filterValue) &&
                !!filterValue.length &&
                isArrayOfType(filterValue, this.isCollectionDataSeedWithIdentifierProperty.bind(this));
			}).map((entry) => {
				return {
					key: entry[0],
					value: entry[1].filter((value) => value._id !== value._identifierProperty),
				};
			});

		for (const filterValueToSwap of filterValuesToSwap) {
			if (!isString(filters[filterValueToSwap.key])) {
				continue;
			}
			filters[filterValueToSwap.key] = filterValueToSwap.value.map((seed) => seed._identifierProperty).join(',');
		}

		return filters;
	}

	// TODO Once collections conform to string id format, we can use the existing isDataSeed function from the sdk to
	// remove duplicated checks and simply check for the addition _identifierProperty
	private isCollectionDataSeedWithIdentifierProperty = (value: unknown): value is CollectionDataSeedWithIdentifierProperty =>
		isDictionary(value) &&
        isNumber(value._id) &&
        isString(value._display) &&
        isStringNotEmpty(value._identifierProperty);

	private updatePresetFilterEntry() {
		const presetOptions: Option [] = this.dateIntervalPresets.map((preset) => {
			return { identifier: preset.label, name: preset.label };
		});

		const index = this.filterManagerAll.entries.findIndex(((entry) => entry.identifier === 'preset'));

		if (this.filterManagerAll.entries[index]?.options) {
			this.filterManagerAll.entries[index].options = presetOptions;
		}
	}

}
