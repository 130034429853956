import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, CreatePasswordConfig, SharedTermsTranslationKey, ToastService, UfControl, UfCreatePasswordComponent, UfFormBuilder } from '@unifii/library/common';
import { ErrorType, MeClient, TenantClient, ensureUfRequestError, isBoolean, isDictionary, isOptionalType, isString, isValueOfStringEnumType } from '@unifii/sdk';

import { Config } from 'config';
import { MFAPath, PasswordChangePath, ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { MfaStatus } from 'shell/services/shell-authentication.service';

import { MfaComponentNavigationState } from './mfa.component';

type PasswordChangeLinkParams = {
    tenant?: string;
    token?: string;
};

export interface PasswordChangeComponentNavigationState {
    oldPassword?: string;
    isViaLink?: boolean;
    params?: PasswordChangeParams;
}

interface PasswordChangeParams{
    projectId?: string;
}

export const isPasswordChangeComponentNavigationState = (data: unknown): data is PasswordChangeComponentNavigationState =>
    isDictionary(data) &&
    isOptionalType(data.oldPassword, isString) &&
    isOptionalType(data.isViaLink, isBoolean) &&
    isOptionalType(data.params, isPasswordChangeParams);

const isPasswordChangeParams = (data: unknown): data is PasswordChangeParams =>
    isDictionary(data) &&
    isOptionalType(data.projectId, isString);

/**
 * Via changePasswordOnNextLogin after successful login
 *  Update via Provisioning.updatePassword as authenticated user
 *
 * Via email link
 *  Update via Provisioning.updatePassword as anonymous user with token
 */
@Component({
    selector: 'ud-password-change',
    templateUrl: 'password-change.html',
})
export class PasswordChangeComponent implements OnInit {

    @ViewChild(UfCreatePasswordComponent) private createPasswordComponent?: UfCreatePasswordComponent;

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected passwordConfig: CreatePasswordConfig;
    protected control: UfControl;
    protected linkError?: AppError;
    protected submitError?: AppError;
    protected busy: boolean;
    protected isViaLink: boolean;

    private token?: string;
    private tenant?: string;
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private authentication = inject(Authentication);
    private errorService = inject(ErrorService);
    private translate = inject(TranslateService);
    private meClient = inject(MeClient);
    private context = inject(DiscoverContext);
    private toastService = inject(ToastService);
    private ufb = inject(UfFormBuilder);
    private tenantClient = inject(TenantClient);
    private config = inject(Config);
    // state is provided when required password change after login, undefined when user opted password change
    private state: PasswordChangeComponentNavigationState | undefined = isPasswordChangeComponentNavigationState(history.state) ? history.state : undefined;

    ngOnInit() {
        const params = this.route.snapshot.queryParams as PasswordChangeLinkParams;

        this.tenant = params.tenant;
        this.token = params.token;
        this.isViaLink = this.state?.isViaLink || (!!this.token && !!this.tenant);
        this.control = this.ufb.control({ value: {} });
        this.passwordConfig = {
            showOldPassword: !this.isViaLink && !this.state?.oldPassword,
            isRequired: true,
            showStrengthIndicator: true,
            canCopy: true,
            canGenerate: true,
        };

        if (!!this.token && !!this.tenant) {
            void this.configureViaLinkMode();
        }
    }

    protected async submit() {
        this.control.setSubmitted();

        // Verify validity of password input internal control validators
        if (!this.createPasswordComponent?.groupControl.valid) {
            return;
        }

        try {
            this.submitError = undefined;
            this.busy = true;

            if (this.isViaLink) {
                await this.meClient.resetPassword({
                    password: this.control.value.password,
                });
                this.context.isPasswordReset = null;
            } else {

                if (!this.oldPassword) {
                    throw new Error();
                }

                // todo review old password
                this.authentication.userInfo = await this.meClient.updatePassword({
                    oldPassword: this.oldPassword,
                    password: this.control.value.password,
                });

                // update permissions
                const permissions = await this.meClient.getPermissions();

                this.authentication.userPermissions = PermissionsFunctions.normalizePermissions(permissions);
            }

            if (this.authentication.isMfaSetupRequired()) {
                void this.router.navigate(['/', UserAccessRootPath, MFAPath], { state:
                    { mfaStatus: MfaStatus.MfaSetupRequired, params: this.state?.params, nextRoute: ['/', UserAccessRootPath, ProjectSelectionPath] } satisfies MfaComponentNavigationState });

                return;
            }

            this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));

            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

        } catch (e) {
            const error = ensureUfRequestError(e);

            switch (error.type) {
                case ErrorType.Unauthorized:
                    this.submitError = this.errorService.createError(this.errorService.passwordChangeInvalidLinkErrorMessage);

                    return;
                case ErrorType.Validation:
                    if (isDictionary(error.data) && error.data.code === 'InvalidPassword') {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeInvalidPasswordErrorMessage);
                    } else if (isDictionary(error.data) && error.data.code === 'PasswordNotChanged') {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeCannotBeSameErrorMessage);
                    } else {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
                    }
                    break;
                default:
                    this.submitError = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
            }

        } finally {
            this.busy = false;
        }
    }

    protected logout() {
        void this.authentication.logout();
    }

    private async configureViaLinkMode() {

        if (!this.token) {
            return;
        }

        try {
            this.busy = true;

            // apply tenant from link param
            this.config.unifii.tenant = this.tenant;
            this.config.unifii.tenantSettings = await this.tenantClient.getSettings();
            this.context.isPasswordReset = true;
            await this.authentication.login({ reset_token: this.token });
        } catch (e) {

            const error = ensureUfRequestError(e);

            if (isDictionary(error.data) && isValueOfStringEnumType(MfaStatus)(error.data.mfaStatus)) {
                void this.router.navigate(['/', UserAccessRootPath, MFAPath], { state:
                    { mfaStatus: error.data.mfaStatus, nextRoute: ['/', PasswordChangePath], nextState: { isViaLink: true } satisfies PasswordChangeComponentNavigationState } satisfies MfaComponentNavigationState });

                return;
            }

            this.linkError = this.errorService.createError(
                this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidLink),
            );
        } finally {
            this.busy = false;
        }
    }

    private get oldPassword(): string | undefined {

        if (this.state?.oldPassword) {
            return this.state.oldPassword;
        }

        const controlOldPassword = this.control.value.oldPassword;

        if (isString(controlOldPassword)) {
            return controlOldPassword;
        }

        return undefined;
    }

}
